<template>
  <div class="page">
    <navbar index="5" />

    <!-- banner begain -->    
    <banner :style="initBannerHeight" />
    <!-- banner end -->
    
    <div class="main-content">

      <!-- 新闻 begain -->
      <div class="news-box" id="news">
        <div class="container-fluid news-content">
          <div class="row">
            <div class="col-12">
              <div class="news-item">
                <div class="news-img"><img src="../assets/img/news_1.jpg" class="img-fluid" alt=""></div>
                <div class="news-info">
                  <div class="title">诚派科技与江苏集萃药康生物科技有限公司达成合作啦!</div>
                  <div class="desc">2018年9月8号，诚派科技与江苏集萃药康生物科技有限公司达成合作啦!诚派科技受集萃药康委托开发其内部生产管理系统。实验动物信息化管理系统是-款专业从事实验动物小鼠模型的研发、生产、销售及相关技术服务的软件产品。</div>
                </div>
              </div>
              <div class="news-item">
                <div class="news-img"><img src="../assets/img/news_2.jpg" class="img-fluid" alt=""></div>
                <div class="news-info">
                  <div class="title">喜讯!诚派科技南京有限公司荣获国家级高新技术企业称号!</div>
                  <div class="desc">喜讯! 2019年7月，诚派科技南京有限公司荣获国家级高新技术企业称号!诚派科技专注于移动互联网领域的研发与大数据商业化应用。APP开发(IOS系统应用开发Android系统应用开发、HTML .5应用开发)，专业的团队为客户提供设计咨询，定制研发，数据分析,技术运维等一体化综合解决方案。</div>
                </div>
              </div>
              <div class="news-item">
                <div class="news-img"><img src="../assets/img/news_3.jpg" class="img-fluid" alt=""></div>
                <div class="news-info">
                  <div class="title">历经一年的努力，完成了智慧管廊以及管廊APP的开发!</div>
                  <div class="desc">2020年5月诚派科技不辜负江苏安防科技委托，历经1年的努力，终于完成了智慧管廊以及管廊APP的开发，并成功交付上线，可喜可贺!智慧管廊移动平台为打造城市地下管廊体系智慧化，实现管网规划、建设、运行、维护及管理、服务的智慧化，保障管网全生命期的安全、高效、智能，从而提升城市基础设施功能和城市运行能力。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新闻 end -->

      <!-- 招聘信息 begain -->
      <!-- <div class="jobs-box" id="jobs">
        <section-title title="RECRUITMENT INFORMATION/招聘信息" />
        <div class="jobs-notice">
          <p>简历投递邮箱</p>
          <p>cpkj@chengpai.net.cn</p>
          <p>(请注明投递岗位)</p>
        </div>
        <div class="container-fluid jobs-content">
          <div class="row">
            <div class="col-8 offset-2 jobs-list">
              <div class="jobs-info">
                <div class="btn-operate prev" @click="turnPage('prev')"><b-icon icon="chevron-left" style="width: 40px; height: 40px;color: #2662FF;"></b-icon></div>
                <div class="btn-operate next" @click="turnPage('next')"><b-icon icon="chevron-right" style="width: 40px; height: 40px;color: #2662FF;"></b-icon></div>
                <div class="jobs-item" v-for="(item, index) in jobsList" :key="index" v-show="jobsIndex == index">
                  <div class="job-name">{{item.name}}</div>
                  <div class="job-location">
                    <div>所属部门: {{item.depar}}</div>
                    <div>工作地点: {{item.location}}</div>
                  </div>
                  <div class="job-desc">
                    <h4>岗位职责</h4>
                    <div v-html="item.duty"></div>
                    <h4>任职资格</h4>
                    <div v-html="item.qualifications"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 招聘信息 end -->

      <!-- 加入我们 begain -->
      <div class="join-box" id="join">
        <section-title title="JOIN US/加入我们" />
        <div class="jobs-notice">
          <p>简历投递邮箱</p>
          <p>cpkj@chengpai.net.cn</p>
          <p>(请注明投递岗位)</p>
        </div>
        <div class="join-content">
          <div class="container-fluid join-tab">
            <div class="tab-item" :class="jobsIndex == '0' ? 'active' : ''" @click.stop="switchJob(0)">
              <img src="../assets/img/nan_1.png" alt="前端程序员">
              <p>前端程序员</p>
            </div>
            <div class="tab-item" :class="jobsIndex == '1' ? 'active' : ''" @click.stop="switchJob(1)">
              <img src="../assets/img/nan_2.png" alt="后端程序员">
              <p>后端程序员</p>
            </div>
            <div class="tab-item" :class="jobsIndex == '2' ? 'active' : ''" @click.stop="switchJob(2)">
              <img src="../assets/img/nan_3.png" alt="产品经理">
              <p>产品经理</p>
            </div>
            <div class="tab-item" :class="jobsIndex == '3' ? 'active' : ''" @click.stop="switchJob(3)">
              <img src="../assets/img/nan_4.png" alt="UI设计师">
              <p>UI设计师</p>
            </div>
          </div>
          <div class="container-fluid tab-content">
            <div class="content-item" v-for="(item, index) in jobsList" :key="index" :class="jobsIndex == index ? 'active' : ''">
              <!-- <div class="job-name">{{item.name}}</div> -->
              <!-- <div class="job-location">
                <div>所属部门: {{item.depar}}</div>
                <div>工作地点: {{item.location}}</div>
              </div> -->
              <div class="job-desc">
                <h4>岗位职责</h4>
                <div v-html="item.duty"></div>
                <h4>任职资格</h4>
                <div v-html="item.qualifications"></div>
              </div>
              <a :href="item.url" class="job-btn" target="__blank">加入我们</a>
            </div>
          </div>
        </div>
      </div>
      <!-- 加入我们 end -->

    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  import {locatePoint} from '@/mixin/locatePoint'
  export default {
    name: 'News',
    mixins: [locatePoint],
    computed: {
      initBannerHeight: () => {
        const W = 1920, H = 520, _W = window.screen.width
        return 'height: ' + (H * _W) / W + 'px'
      }
    },
    data () {
      return {
        jobsIndex: 0,
        jobsList: [
          {
            name: '前端程序员',
            dep: '研发部门',
            location: '南京',
            duty: '<p>1、负责公司产品移动端、网页端的界面开发及维护工作;</p><p>2、参与需求设计讨论、优化产品的用户体验;</p><p>3、参与第三方平台对接、界面融合等工作;</p><p>4、对产品移动端、网页端进行持续化和改进;</p>',
            qualifications: '<p>1、三年以上前端开发经验，扎实的HTML、Javascript、CSS前端开发基础技术;</p><p>2、熟练使用vue.js、vuex.js前端基础框架;</p><p>3、熟练使用vantUl、elementUI框架;</p><p>4、有屏幕兼容性调试经验优先;</p>',
            url: 'https://www.zhipin.com/gongsi/32d0c99fe77c875603Z42tW_.html?ka=search_rcmd_company_name_32d0c99fe77c875603Z42tW__custompage'
          },
          {
            name: '后端程序员',
            dep: '研发部门',
            location: '南京',
            duty: '<p>1、JAVA基础扎实，熟悉常用的设计模式，熟悉io.多线程，集合等基础组件，熟悉分布式，缓存，消息等机制;</p><p>2、熟练掌握spring，springmvc，springboot，springcloud , dubbo等服务端开发框架;</p><p>3、熟练掌握MySQL等数据库的使用以及mybatis等框架;</p><p>4、熟练掌握redis，Kafka，activeMQ等技术，并有实际项目开发经验;</p><p>5、熟悉linux基本操作命令，有实际操作经验优先</p><p>6、熟悉报表BI开发（会使用帆软的优先）;</p>',
            qualifications: '<p>1、3年及以上相关工作经验;</p><p>2、具有团队合作意识;</p><p>3、朝九晚六，周末双休;</p>',
            url: 'https://www.zhipin.com/gongsi/32d0c99fe77c875603Z42tW_.html?ka=search_rcmd_company_name_32d0c99fe77c875603Z42tW__custompage'
          },
          {
            name: '产品经理',
            dep: '研发部门',
            location: '南京',
            duty: '<p>1、负责B端和C端大数据以及区块链等产品的产品规划、需求分析、竞品调研、产品设计、撰写文档等工作;</p><p>2、协同设计、研发、测试推进产品上线落地，并推动持续优化迭代;</p><p>3、负责撰写产品PRD、MRD文档、原型设计、需求文档编写、产品上线培训等工作;</p><p>4、对大数据分析、联盟链和公链等有深入了解可以优先考虑;</p>',
            qualifications: '<p>1、2年以上产品经理相关工作经验，第一学历为全日制本科及以上学历，计算机、金融或统计学相关专业优先;</p><p>2、具备良好的产品感、用户感，对行业头部产品动态感知敏锐 ，熟悉需求调研方法，较强的业务流程及业务模型分析设计能力;</p><p>3、有较强的原型文档编写能力，熟练visio、Axure、ppt、思维导图等工具;</p><p>4、具有很强的自驱力、学习能力、沟通能力、逻辑思维能力；积极主动、具有团队合作精神;</p>',
            url: 'https://www.zhipin.com/gongsi/32d0c99fe77c875603Z42tW_.html?ka=search_rcmd_company_name_32d0c99fe77c875603Z42tW__custompage'
          },
          {
            name: 'UI设计师',
            dep: '研发部门',
            location: '南京',
            duty: '<p>1、参与公司移动端APP、WEB端产品的UI及交互设计工作，并编写交互设计规范;</p><p>2、根据PM提供的信息架构及业务需求、完成流程设计和原型交互设计，提高产品的易用性;</p><p>3、根据确定的项目需求，按时完成高质量的产品视觉设计;</p><p>4、配合相关人员进行产品界面优化，持续优化产品的美观度和用户体验;</p><p>5、兼做过UE和测试经历的优先考虑;</p><p>6、撰写调研报告，撰写产品可行性分析报告;</p><p>7、要求有过画产品原型经验的;</p>',
            qualifications: '<p>1、具备产品市场调研、需求分析、功能设计及已有产品的迭代设计能力;</p><p>2、具备良好的审美意识，负责Web/App视觉设计，必须会画原型图，思维导图，和可视化大屏设计经验;</p><p>3、2年及以上互联网UI设计经验，精通各种相关绘图软件协助开发人员实现产品;</p><p>4、参与用户研究和产品可用性分析，跟踪和评估产品体验;</p><p>5、配合项目负责人，制订UI设计规范及流程，具备项目汇报经验;</p><p>6、具备方案撰写，ppt设计美化能力，有过智慧城市类项目设计经验;</p><p>7、简历需包含相关作品集;</p>',
            url: 'https://www.zhipin.com/gongsi/32d0c99fe77c875603Z42tW_.html?ka=search_rcmd_company_name_32d0c99fe77c875603Z42tW__custompage'
          }
        ]
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.LP();
      })
    },
    components: {
      navbar,
      banner,
      sectionTitle,
      foot
    },
    methods: {
      switchJob (index) {
        if (this.jobsIndex == index) return
        this.jobsIndex = index
      },
      turnPage (direction) {
        if (direction == 'next') {
          if (this.jobsIndex + 1 < this.jobsList.length) {
            this.jobsIndex = this.jobsIndex + 1
          }
        }
        if (direction == 'prev') {
          if (this.jobsIndex - 1 >= 0) {
            this.jobsIndex = this.jobsIndex - 1
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background: url('../assets/img/banner_news.jpg') center top no-repeat;
    background-size: contain;
    .news-box {
      .news-content {
        max-width: 1300px;
        .news-item {
          margin-bottom: 40px;
          padding: 30px;
          display: flex;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, .4);
          .news-img {
            margin-right: 30px;
            flex: 0 0 300px;
          }
          .news-info {
            color: #666;
            .title {
              font-size: 28px;
              font-weight: bold;
            }
            .desc {
              font-size: 18px;
            }
          }
        }
      }
    }
    // .jobs-box {
    //   margin: 0 auto;
    //   padding: 60px 0;
    //   .jobs-notice {
    //     margin-top: 60px;
    //     text-align: center;
    //     color: #666;
    //     p {
    //       margin-bottom: 10px;
    //       line-height: 1;
    //       font-size: 26px;
    //       &:nth-child(2) {
    //         font-size: 22px;
    //       }
    //       &:nth-child(3) {
    //         font-size: 18px;
    //       }
    //     }
    //   }
    //   .jobs-content {
    //     margin-top: 60px;
    //     .jobs-list {
    //       .jobs-info {
    //         position: relative;
    //         width: 100%;
    //         padding: 60px 190px;
    //         background-color: #F8F9FD;
    //         box-shadow: 0 0 5px 1px rgba(0, 0, 0, .4);
    //         border-radius: 10px;
    //         .btn-operate {
    //           position: absolute;
    //           left: -40px;
    //           top: 50%;
    //           transform: translateY(-50%);
    //           padding-top: 20px;
    //           width: 80px;
    //           height: 80px;
    //           border-radius: 40px;
    //           box-shadow: 0 8px 21px 0 rgba(38, 98, 255, 0.22);
    //           background-color: #fff;
    //           overflow: hidden;
    //           z-index: 9;
    //           text-align: center;
    //           cursor: pointer;
    //           &.next {
    //             left: auto;
    //             right: -40px;
    //           }
    //         }
    //         .jobs-item {
    //           .job-name {
    //             margin-bottom: 30px;
    //             line-height: 1;
    //             font-size: 24px;
    //             font-weight: bold;
    //             color: #333;
    //           }
    //           .job-location {
    //             padding-bottom: 30px;
    //             line-height: 1;
    //             border-bottom: 1px solid #ccc;
    //             div {
    //               display: inline-block;
    //               &:first-child {
    //                 margin-right: 90px;
    //               }
    //             }
    //           }
    //           .job-desc {
    //             color: #545454;
    //             font-size: 20px;
    //             h4 {
    //               margin-top: 30px;
    //               margin-bottom: 20px;
    //               font-size: 24px;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .join-box {
      margin: 0 auto;
      padding: 60px 0;
      .jobs-notice {
        margin-top: 60px;
        text-align: center;
        color: #666;
        p {
          margin-bottom: 10px;
          line-height: 1;
          font-size: 26px;
          &:nth-child(2) {
            font-size: 22px;
          }
          &:nth-child(3) {
            font-size: 18px;
          }
        }
      }
      .join-content {
        margin-top: 60px;
        .join-tab {
          margin-top: 100px;
          display: flex;
          max-width: 1300px;
          .tab-item {
            position: relative;
            flex: 0 0 25%;
            text-align: center;
            cursor: pointer;
            img {
              margin-bottom: 20px;
            }
            p {
              line-height: 1;
              font-size: 20px;
              color: #666;
            }
            &::after {
              content: '';
              width: 0;
              height: 6px;
              background-color: #2663FF;
              position: absolute;
              left: 0;
              bottom: 0;
              transition: width .2s ease-in;
            }
            &:hover::after, &.active::after {
              width: 100%;
            }
          }
        }
        .tab-content {
          padding: 80px 80px 120px 80px;
          max-width: 1300px;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, .4);
          border-radius: 4px;
          .content-item {
            display: none;
            position: relative;
            &.active {
              display: block;
            }
            .job-btn {
              position: absolute;
              right: 10px;
              bottom: -100px;
              padding: 15px 20px;
              line-height: 1;
              font-size: 18px;
              color: #fff;
              background-color: #2663FF;
              border-radius: 8px;
            }
            .job-name {
              margin-bottom: 30px;
              line-height: 1;
              font-size: 24px;
              font-weight: bold;
              color: #333;
            }
            .job-location {
              padding-bottom: 30px;
              line-height: 1;
              border-bottom: 1px solid #ccc;
              div {
                display: inline-block;
                &:first-child {
                  margin-right: 90px;
                }
              }
            }
            .job-desc {
              font-size: 18px;
              color: #545454;
              h4 {
                font-size: 20px;
                margin-top: 30px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
</style>